<template>
  <v-footer
      color="primary lighten-1"
      padless
  >
    <v-row
        justify="center"
        no-gutters
    >
      <v-btn
          v-for="(link,index) in links"
          :key="index"
          color="white"
          :href="link.path"
          text
          rounded
          retain-focus-on-click
          class="my-2">
        {{ link.title }}
      </v-btn>
      <v-col
          class="white py-4 text-center primary--text pb-0"
          cols="12">

        <v-row style="margin: 0;padding: 30px">
          <v-img
              :src="require('../assets/aday_muh_main.png')"
              width="220px"
              contain
              class="mr-3"
          ></v-img>

          <v-col style="text-align: initial; min-width: 200px" >
            <h3><strong>{{ flowMenu.subMenu[1].title }}</strong></h3>
            <v-divider light></v-divider>
            <div v-for="(item,index) in flowMenu.subMenu[1].subMenu" :key="index">
              <a style="color:#4398ae;text-decoration: none" :href="item.path">{{ item.title }}</a>
            </div>
          </v-col>

          <v-col style="text-align: initial; min-width: 200px" >
            <h3><strong>{{ flowMenu.subMenu[0].title }}</strong></h3>
            <v-divider light></v-divider>
            <div v-for="(item,index) in flowMenu.subMenu[0].subMenu" :key="index">
              <a style="color:#4398ae;text-decoration: none" :href="item.path">{{ item.title }}</a>
            </div>


          </v-col>

          <v-col style="text-align: initial; min-width: 200px" >
            <h3><strong>{{ flowMenu.subMenu[2].title }}</strong></h3>
            <v-divider light></v-divider>
            <div v-for="(item,index) in flowMenu.subMenu[2].subMenu" :key="index">
              <a style="color:#4398ae;text-decoration: none" :href="item.path">{{ item.title }}</a>
            </div>


          </v-col>

          <v-col style="text-align: initial">
            <h3><strong>Contact</strong></h3>
            <v-divider light></v-divider>
            <div>
              Email: <a style="color: #4398ae" href="mailto:sales@adaymuhendislik.com">sales@adaymuhendislik.com</a>
            </div>
            <div>
              Tel: <a style="color: #4398ae" href="tel:+905333725057">+90 (533) 372 50 57</a>
            </div>
            <a style="color: #4398ae" href="tel:+905326009602">+90 (532) 600 96 02</a>
            <div>
              Address: Altunizade Mah.Yetimhane Sok. Fettahoğlu Apt No: 14 D:1 | 34662 | Üsküdar | Istanbul | Turkey
            </div>
          </v-col>

          <v-col style="text-align: initial">
            <h3><strong>Social Media</strong></h3>
            <v-divider light></v-divider>

            <v-layout justify-center  style="background: #4398ae;width: 40px;height: 40px;margin-top: 4px">
            <a href="https://www.linkedin.com/company/aday-muhendislik" target="_blank">
              <v-img class="mt-2"
                     width="27"
                     :src="require('../assets/linkedin.svg')"
                     contain

              ></v-img>
            </a>
            </v-layout>
          </v-col>
        </v-row>

        <v-divider light></v-divider>

        <div class="pa-2">
          {{ new Date().getFullYear() }} — <strong>Aday Mühendislik</strong>
        </div>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import Vue from "vue";

export default {
  name: "Footer",
  data: () => ({
    links: [
      {title: 'Home', path: '/'},
      {title: 'About Us', path: '/about'},
      {title: 'Contact Us', path: '/contact'},
    ],
    flowMenu: Vue.prototype.flowMenu
  }),


}
</script>

<style scoped>

</style>