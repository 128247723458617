<template>
  <v-card class="ma-3">

    <v-simple-table>
      <template v-slot:default>

        <tbody>
        <tr
            v-for="item in cardInfo"
            :key="item.name"
        >
            <th  style="background: rgba(67,152,174,0.5)">{{ item.name }}</th>

          <td>
            <v-col>
              <v-card-text class="pa-0" v-for="(info) in item.infos" v-text="info"
                           :key="info.name"></v-card-text>
            </v-col>
          </td>

        </tr>
        </tbody>
      </template>
    </v-simple-table>

  </v-card>
</template>

<script>
export default {
  name: "CardTable",
  props: {
    cardInfo: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>

</style>