<template>
  <div>
  <h2 class="mt-12 ml-3 mr-3" style="color: #22546b">Documents</h2>
  <v-divider class="ml-3 mr-3"></v-divider>
  <v-row class="ma-0" >
    <v-col v-for="(item,index) in documents" :key="index" style="max-width: 130px"  class="text-center">
      <v-card
          style="margin: auto"
          :href="require('../files'+item.pathname).default"
          :download="item.name"
          target="_blank"
          width="80px"
          height="100px">
        <v-icon
            class="text-center fill-height"
            style="width: 100%"
            x-large
            color="primary"
            light
        > mdi-folder-open</v-icon>
      </v-card>

      <v-card-text v-text="item.name"></v-card-text>
    </v-col>
  </v-row>
  </div>
</template>

<script>
export default {
  name: "Documents",
  props:{
    documents:{
      type:Array,
      required:true
    }
  }
}
</script>

<style scoped>

</style>