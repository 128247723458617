<template>
  <v-container class="pt-12">

    <v-layout row wrap>
      <v-flex class="lg3 xl3 mr-4">
        <FlowMenu :title="title"></FlowMenu>

      </v-flex>
      <v-flex class="lg8 xl8">
        <v-col class="ma-0 pa-0">

          <v-img class="mr-auto"
                 height="400"
                 :src="require('../../assets/'+img)"
          ></v-img>
          <h2 class="mt-12 mr-3 ml-3" style="color: #22546b">{{ this.$data.title }}</h2>
          <v-divider></v-divider>


          <CardTable :card-info="card_infos"></CardTable>

          <Documents :documents="this.documents"></Documents>
        </v-col>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Documents from "@/components/Documents";
import CardTable from "@/components/CardTable";
import FlowMenu from "@/components/FlowMenu";

export default {
  name: "SealGasBooster",
  components: {Documents, CardTable, FlowMenu},
  data: () => ({
    img:'seal_gas_booster.jpg',
    title: 'Seal Gas Booster',
    card_infos: [
      {
        name: 'SERIES',
        infos: ['SGB20'],
      },
      {
        name: 'RATINGS',
        infos: ['LP VERSION',
        'MP VERSION',
        'HP VERSION'],
      },
      {
        name: 'DESIGN STANDARTS',
        infos: [
          'ASME B16.34 - ASME PV CODE 8 2014/68/UE P.E.D. NACE MR0175',
          'ATEX 94/9/CE'],
      },
      {
        name: 'DESIGN PRESSURE',
        infos: ['120 BAR-G 220 BAR-G 350 BAR-G'],
      },
      {
        name: 'PROCESS FLUID',
        infos: ['NATURAL GAS OR OTHERS'],
      },
      {
        name: 'MOUNTING',
        infos: [
          'HORIZONTAL OR WALL',
          '(Vertical with horizontal movement of the piston'
        ],
      },
      {
        name: 'WEIGHT',
        infos: ['APPROX. KG 220'],
      },
      {
        name: 'DESIGN TEMPERATURE °C',
        infos: ['UP TO 80 °C / UP TO 140 °C (HT VERSION)'],
      },
      {
        name: 'DESIGN FLOW',
        infos: ['7.2 Am3/h @ 60 CYCLES PER MINUTE'],
      },
      {
        name: 'DESIGN STANDARTS',
        infos: ['1 BAR'],
      },
      {
        name: 'DESIGN STANDARTS ',
        infos: ['2000 cm3'],
      },
      {
        name: 'PNEUM. CYLINDERS CONSUMPTION',
        infos: ['40 Sm3/h']
      },
      {
        name: 'INSTRUMENT AIR SUPPLY',
        infos: ['Min. 4 - Norm. 6 - Max 6 BAR-G']
      },
      {
        name: 'STANDART MATERIALS',
        infos: ['BODY : WCB – CF8 - CF8M – DUPLEX',
          'Other on request',
          'BUTTERFLY :  CF8M – DUPLEX – CA6NM',
          'Other on request',
          'STEM : AISI 316 – AISI 420B – 17.4.PH MONEL NITRONIC 50',
          'Other on request',
          'SEAL SEAT : ',
          'Flexible Metal Seat: AISI 430 - AISI 316 INCOLOY',
          'Metal Seat: Incoloy- AISI 316 + Stellite AISI 316 Hardened',
          'STEM SEAL :  PTFE+NBR / VITON / SILICONE / KALREZ / CARBOGRAPHITE',
          '+ NBR / VITON / SILICONE / KALREZ / GRAPHITE'],
      },

    ],
    documents: [
      {name: 'Seal Gas Booster Catalog', pathname: '/seal_gas_booster_catalog.pdf'},
    ]
  }),
}
</script>

<style scoped>

</style>