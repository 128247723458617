<template>

  <v-list

      dense
      rounded
  >
    <v-list-item-group

        v-model="group"
        active-class="primary--text text--accent-4"
    >
      <v-list-group
          :value="true"
      >
        <template v-slot:activator>
          <v-list-item-title v-text="menus.title"></v-list-item-title>
        </template>

        <v-list-group
            v-for="(subGroup,index) in menus.subMenu" :key="index"
            :value="true"
            no-action
            sub-group

        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="subGroup.title"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-flex v-for="(subCategory, index) in subGroup.subMenu" :key="index">
            <v-list-item

                v-if="subCategory.subMenu === undefined"
                link
                :href="subCategory.path"
            >
              <v-list-item-title v-text="subCategory.title"></v-list-item-title>

            </v-list-item>


          </v-flex>
        </v-list-group>


      </v-list-group>


    </v-list-item-group>


  </v-list>
</template>

<script>


import Vue from "vue";

export default {
  name: "FlowMenu",
  props: {
    title: {
      type: String,
      required: true,
    }
  },
  data: () => ({
    menus: Vue.prototype.flowMenu,
    group: undefined,
  }),


  mounted() {
    this.menus.subMenu[0].subMenu.find(category => {
      if (this.title === category.title) {
        this.group = this.menus.subMenu[0].subMenu.indexOf(category) + 2
      }
    })
  }

}
</script>

<style scoped>


</style>