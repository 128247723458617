<template>
<v-container style="text-align: center ;color: #22546b" class="mt-5">
  <v-icon size="200" light color="primary">mdi-alert-decagram-outline</v-icon>
  <v-divider class="mt-5" light></v-divider>
  <h1>Page Not Found</h1>
</v-container>
</template>

<script>
export default {
  name: "NotFound"
}
</script>

<style scoped>

</style>