<template>
  <v-container class="pt-12">

    <v-layout row wrap>
      <v-flex class="lg3 xl3 mr-4">
        <FlowMenu :title="title"></FlowMenu>

      </v-flex>
      <v-flex class="lg8 xl8">
        <v-col class="ma-0 pa-0">

          <v-img class="mr-auto"
                 height="400"
                 :src="require('../../assets/'+img)"
          ></v-img>
          <h2 class="mt-12 mr-3 ml-3" style="color: #22546b">{{ this.$data.title }}</h2>
          <v-divider></v-divider>


          <CardTable :card-info="card_infos"></CardTable>
          <v-card class="mt-5  mr-3 ml-3">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">
                    EXECUTION
                  </th>
                  <th class="text-left">
                    SERIES
                  </th>
                  <th class="text-left">
                    SIZE
                  </th>
                  <th class="text-left">
                    MAX PRESSURE
                  </th>
                  <th class="text-left">
                    MAX TEMP
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr
                >
                  <td>2P MODULE</td>
                  <td>
                    <v-col class="pa-0">
                      <v-card-text class="pa-0" >BLVF</v-card-text>
                      <v-card-text class="pa-0" >BLVF-TEF-HC-MF</v-card-text>
                      <v-card-text class="pa-0" >BLVF-TEF-HC-MF/HT</v-card-text>
                    </v-col>
                  </td>
                  <td>
                    <v-col class="pa-0" >
                      <v-card-text class="pa-0" >20-25-40</v-card-text>
                      <v-card-text class="pa-0" >20-25-40</v-card-text>
                      <v-card-text class="pa-0" >20-25-40</v-card-text>
                    </v-col>
                  </td>
                  <td>
                    <v-col class="pa-0" >
                      <v-card-text class="pa-0" >120 BAR</v-card-text>
                      <v-card-text class="pa-0" >160 BAR</v-card-text>
                      <v-card-text class="pa-0" >135 BAR</v-card-text>
                    </v-col>
                  </td>
                  <td>
                    <v-col class="pa-0" >
                      <v-card-text class="pa-0" >150 °C</v-card-text>
                      <v-card-text class="pa-0" >180 °C</v-card-text>
                      <v-card-text class="pa-0" >260 °C</v-card-text>
                    </v-col>
                  </td>
                </tr>
                <tr
                >
                  <td>3P MODULE</td>
                  <td>
                    <v-col class="pa-0">
                      <v-card-text class="pa-0" >BLVF</v-card-text>
                      <v-card-text class="pa-0" >BLVF-HC</v-card-text>
                      <v-card-text class="pa-0" >BLVF-HC-MF</v-card-text>
                      <v-card-text class="pa-0" >BLVF-TEF-HC-MF</v-card-text>
                      <v-card-text class="pa-0" >BLVF-TEF-HC-MF/HT</v-card-text>
                    </v-col>
                  </td>
                  <td>
                    <v-col class="pa-0" >
                      <v-card-text class="pa-0" >20-25-40</v-card-text>
                      <v-card-text class="pa-0" >20-25</v-card-text>
                      <v-card-text class="pa-0" >20-25-40</v-card-text>
                      <v-card-text class="pa-0" >20-25-40</v-card-text>
                      <v-card-text class="pa-0" >20-25-40</v-card-text>
                    </v-col>
                  </td>
                  <td>
                    <v-col class="pa-0" >
                      <v-card-text class="pa-0" >120 BAR</v-card-text>
                      <v-card-text class="pa-0" >120 BAR</v-card-text>
                      <v-card-text class="pa-0" >120 BAR</v-card-text>
                      <v-card-text class="pa-0" >120 BAR</v-card-text>
                      <v-card-text class="pa-0" >110 BAR</v-card-text>
                    </v-col>
                  </td>
                  <td>
                    <v-col class="pa-0" >
                      <v-card-text class="pa-0" >150 °C</v-card-text>
                      <v-card-text class="pa-0" >150 °C</v-card-text>
                      <v-card-text class="pa-0" >180 °C</v-card-text>
                      <v-card-text class="pa-0" >180 °C</v-card-text>
                      <v-card-text class="pa-0" >260 °C</v-card-text>

                    </v-col>
                  </td>
                </tr>
                <tr
                >
                  <td>5P MODULE</td>
                  <td>
                    <v-col class="pa-0">
                      <v-card-text class="pa-0" >BLVF</v-card-text>
                      <v-card-text class="pa-0" >BLVF-HC</v-card-text>
                      <v-card-text class="pa-0" >BLVF-HC-MF</v-card-text>
                      <v-card-text class="pa-0" >BLVF-TEF-HC-MF</v-card-text>
                      <v-card-text class="pa-0" >BLVF-TEF-HC-MF/HT</v-card-text>
                    </v-col>
                  </td>
                  <td>
                    <v-col class="pa-0" >
                      <v-card-text class="pa-0" >20-25-40</v-card-text>
                      <v-card-text class="pa-0" >20-25</v-card-text>
                      <v-card-text class="pa-0" >20-25-40</v-card-text>
                      <v-card-text class="pa-0" >20-25-40</v-card-text>
                      <v-card-text class="pa-0" >20-25-40</v-card-text>
                    </v-col>
                  </td>
                  <td>
                    <v-col class="pa-0" >
                      <v-card-text class="pa-0" >360 BAR</v-card-text>
                      <v-card-text class="pa-0" >360 BAR</v-card-text>
                      <v-card-text class="pa-0" >360 BAR</v-card-text>
                      <v-card-text class="pa-0" >360 BAR</v-card-text>
                      <v-card-text class="pa-0" >320 BAR</v-card-text>
                    </v-col>
                  </td>
                  <td>
                    <v-col class="pa-0" >
                      <v-card-text class="pa-0" >150 °C</v-card-text>
                      <v-card-text class="pa-0" >150 °C</v-card-text>
                      <v-card-text class="pa-0" >180 °C</v-card-text>
                      <v-card-text class="pa-0" >180 °C</v-card-text>
                      <v-card-text class="pa-0" >260 °C</v-card-text>

                    </v-col>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
          <Documents :documents="this.documents"></Documents>
        </v-col>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Documents from "@/components/Documents";
import CardTable from "@/components/CardTable";
import FlowMenu from "@/components/FlowMenu";

export default {
  name: "GasModularSystem",
  components: {Documents, CardTable, FlowMenu},
  data: () => ({
    img:'gas_filtration.jpg',
    title: 'Gas Filtration Modular System',
    card_infos: [
      {
        name: 'DESIGN',
        infos: ['ASME B16.34',
        'ASME III - ASME PV CODE 8',
        '2014/68/UE P.E.D.',
        'SIL IEC 61508',
        'ISO 15848 FUGITIVE EMISSIONS'],
      },
      {
        name: 'ENDS CONNECTION',
        infos: ['•\t WELDING NECK SIZE 20-25-40 SCH. 80S - 160S ACC. TO',
        'ASME B16.25 - ASME B16.5',
        '•\t NPT-F SIZE ¾” - 1” ACC. TO ANSI B1.20.1, lateral orfrontal connection',
        '•\t Other special connection on request'],
      },
      {
        name: 'TESTING',
        infos: [
          'MSS-SP61 – FCI 70.2',
          'Special testing on request'],
      },

      {
        name: 'STANDART MATERIALS',
        infos: ['BODIES : ',
        'ASTM A182 F 316L - ASTM A182 F51',
        'ASTM A564-630 - 17.4.PH',
        'ASTM A494 CW 6MC',
        'other on request',
        'TRIM : ',
        'WCB + ENP - A105 + ENP - CF8 - CF8M - DULPLEX - CA6NM',
        'Other on request',
        'SEAL SEAT :',
        'PTFE / FILLED – PTFE',
        'GASKETS :',
        'VITON - FR58/90 EX-PROOF - AF 69/90',
        'RED SILICON',
        'A90H - KALREX - AISI 316+GRAPHITE'],
      },

    ],
    documents: [
      {name: 'Gas Filtration Modular System ', pathname: '/filtration_gas_catalog.pdf'},
    ]
  }),
}
</script>

<style scoped>

</style>