import Vue from 'vue';
import Router from 'vue-router';
import MainPage from "@/views/MainPage";
import Contact from "@/views/Contact";
import AboutUs from "@/views/AboutUs";
import ButterflyValve from "@/views/flow-control-solutions/ButterflyValve";
import BallValve from "@/views/flow-control-solutions/BallValve";
import CheckValves from "@/views/flow-control-solutions/CheckValves";
import HemisphereValves from "@/views/flow-control-solutions/HemisphereValves";
import GasModularSystem from "@/views/flow-control-solutions/GasModularSystem";
import SealGasBooster from "@/views/flow-control-solutions/SealGasBooster";
import Actuators from "@/views/flow-control-solutions/Actuators";
import NotFound from "@/views/NotFound";


Vue.use(Router);

export default new Router({
  mode:'history',
  routes: [
    {
      path: '/',
      name: 'MainPage',
      component: MainPage,
      meta: {
        title:'Aday  Mühendislik'
      }

    },
    {
      path: '/contact',
      name: 'Contact',
      component: Contact,
      meta: {
        title:'Contact'
      }

    },
    {
      path: '/about',
      name: 'About',
      component: AboutUs,
      meta: {
        title:'About Us'
      }

    },
    {
      path: '/butterfly-valves',
      name: 'Butterfly Valves',
      component: ButterflyValve,
      meta: {
        title:'Butterfly Valves'
      }

    },
    {
      path: '/ball-valves',
      name: 'Ball Valve',
      component: BallValve,
      meta: {
        title:'Ball Valves'
      }
    },
    {
      path: '/check-valves',
      name: 'Check Valves',
      component: CheckValves,
      meta: {
        title:'Check Valves'
      }
    },
    {
      path: '/hemisphere-valves',
      name: 'Hemisphere Valves',
      component: HemisphereValves,
      meta: {
        title:'Hemisphere Valves'
      }
    },
    {
      path: '/gas-filtration-modular-system',
      name: 'Gas Filtration Modular System',
      component: GasModularSystem,
      meta: {
        title:'Gas Filtration Modular System'
      }
    },
    {
      path: '/seal-gas-booster',
      name: 'Seal Gas Booster',
      component: SealGasBooster,
      meta: {
        title:'Seal Gas Booster'
      }
    },
    {
      path: '/actuators',
      name: 'Actuators',
      component: Actuators,
      meta: {
        title:'Actuators'
      }
    },

    {
      path: '*',
      name: 'Not Found',
      component: NotFound,
      meta: {
        title:'Not Found'
      }
    },

  ]
});
