<template>
  <v-container class="pt-12">

    <v-layout row wrap>
      <v-flex class="lg3 xl3 mr-4">
        <FlowMenu :title="title"></FlowMenu>

      </v-flex>
      <v-flex class="lg8 xl8">
        <v-col class="ma-0 pa-0">

          <v-img class="mr-auto"
                 height="400"
                 :src="require('../../assets/'+img)"
          ></v-img>
          <h2 class="mt-12 mr-3 ml-3" style="color: #22546b">{{ this.$data.title }}</h2>
          <v-divider></v-divider>


          <CardTable :card-info="card_infos"></CardTable>

          <Documents :documents="this.documents"></Documents>
        </v-col>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Documents from "@/components/Documents";
import CardTable from "@/components/CardTable";
import FlowMenu from "@/components/FlowMenu";

export default {
  name: "CheckValves",
  components: {Documents, CardTable, FlowMenu},
  data: () => ({
    img:'check_valves.jpg',
    title: 'Check Valves',
    card_infos: [
      {
        name: 'DESIGN',
        infos: ['ASME B16.34',
          'ASME III - ASME PV CODE 8',
          '2014/68/UE P.E.D.',
          'ISO 15848 FUGITIVE EMISSIONS'],
      },
      {
        name: 'FACE TO FACE',
        infos: ['ASME B16.10'],
      },
      {
        name: 'CLASS',
        infos: ['ANSI 150-300-600-900'],
      },
      {
        name: 'SERVICE',
        infos: ['ON/OFF'],
      },
      {
        name: 'ENDS',
        infos: ['FLANGED',
          'BUTTWELD'],
      },
      {
        name: 'SEAL SEATS TYPE',
        infos: [
          'METAL',
        ],
      },
      {
        name: 'ENDS COUPLING',
        infos: ['ASME B16.5 - ASME B16.47 - ASME B16.25'],
      },
      {
        name: 'SIZE',
        infos: ['from 6 inches to 30 inches'],
      },
      {
        name: 'TESTING',
        infos: ['FCI 70.2 - IEC 534 – API 6D - API 598 – UNI EN 12266',
          'Special testing on request'],
      },
      {
        name: 'COUPLING TO ACTUATORS',
        infos: ['ISO 5211 – Other on request'],
      },
      {
        name: 'STANDART MATERIALS',
        infos: ['BODY : WCB – WC6 – WC9',
          'Other on request',
          'CLAPET : CA6NM - AISI 410',
          'Other on request',
          'STEM : AISI 316 – AISI 420B – NITRONIC 50 - Other on request',
          'SEAL SEAT : AISI 316 - AISI 410 WITH STELLITE',
          'BUSHING : D.U. - TG30 - TG61F',
          'STEM SEAL : CARBOGRAPHITE+NBR / VITON / SILICONE',
          'KALREX PTFE PACKING',
          'GRAPHITE PACKING'],
      },

    ],
    documents: [
      {name: 'Check Valve Draw', pathname: '/check_valve_draw.pdf'},
    ]
  }),
}
</script>

<style scoped>

</style>