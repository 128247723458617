<template>

  <div>
    <v-sheet
        color="primary lighten-2"
        height="120px">
      <v-container>
        <v-card-text
            style="color: white;font-size: 40px;margin-top: 20px"
            v-text="'Contact Us'"></v-card-text>
      </v-container>
    </v-sheet>

    <v-sheet
        style="margin-top: 10px"
        color="white">
      <v-container style="text-align: center; color:#22546b;height: 100%; align-content: center">
        <v-row>
          <v-col style="text-align: center; height: 100%; align-content: center">
            <v-img
                :src="require('../assets/location-icon.svg')"
                height="57px"
                contain="contain"
                transition="scale-transition"

            ></v-img>
            <v-card-text
                style="font-size: 30px;margin-top: 10px ;font-weight: bold"
                v-text="'Location'">

            </v-card-text>
            <v-divider light></v-divider>

            <v-card-text
                style="font-size: 16px;margin-top: 10px"
                v-text="'Altunizade Mah.Yetimhane Sok. Fettahoğlu Apt No: 14 D:1 | 34662 | Üsküdar | Istanbul | Turkey'">
            </v-card-text>
          </v-col>
          <v-col style="text-align: center; height: 100%; align-content: center">
            <v-img
                :src="require('../assets/phone-icon.svg')"
                height="57px"
                contain="contain"
                transition="scale-transition"

            ></v-img>
            <v-card-text
                style="font-size: 30px;margin-top: 10px ;font-weight: bold"
                v-text="'Phone'">
            </v-card-text>
            <v-divider light></v-divider>

            <v-card-text style="margin-top: 10px">
              <a
                  style="font-size: 16px;"
                  href="tel:+905333725057">
                +90 (533) 372 50 57
              </a>
            </v-card-text>
            <v-card-text class="ma-0" >
              <a
                  style="font-size: 16px;"
                  href="tel:+905326009602">
                +90 (532) 600 96 02
              </a>
            </v-card-text>
          </v-col>
          <v-col style="text-align: center; height: 100%; align-content: center">
            <v-layout style="padding: 6px; height: 57px">
              <v-img
                  :src="require('../assets/mail-icon-blue.png')"
                  height="100%"
                  contain="contain"
                  transition="scale-transition"
              ></v-img>
            </v-layout>

            <v-card-text
                style="font-size: 30px;margin-top: 10px ;font-weight: bold"
                v-text="'Email'">
            </v-card-text>
            <v-divider light></v-divider>
            <v-card-text style="margin-top: 10px">
              <a
                  style="font-size: 16px;margin-top: 10px ;padding: 16px"
                  href="mailto:sales@adaymuhendislik.com"
              >
                sales@adaymuhendislik.com
              </a>
            </v-card-text>
          </v-col>
          <v-col style="text-align: center; height: 100%; align-content: center">
            <v-img
                :src="require('../assets/link-icon.svg')"
                height="57px"
                contain="contain"
                transition="scale-transition"
            ></v-img>
            <v-card-text
                style="font-size: 30px;margin-top: 10px ;font-weight: bold"
                v-text="'Social Media'">
            </v-card-text>
            <v-divider light></v-divider>

            <v-layout justify-center  style="background: #4398ae;width: 40px;height: 40px;margin-top: 20px;margin-left: auto;margin-right: auto">
              <a href="https://www.linkedin.com/company/aday-muhendislik" target="_blank">
                <v-img class="mt-2"
                       width="27"
                       :src="require('../assets/linkedin.svg')"
                       contain

                ></v-img>
              </a>
            </v-layout>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <v-container>
      <v-sheet>
        <v-form
            v-if="false"
            ref="form"
            v-model="valid"
            lazy-validation
        >
          <v-text-field
              v-model="name"
              :counter="10"
              :rules="nameRules"
              label="Name"
              required
          ></v-text-field>

          <v-text-field
              v-model="email"
              :rules="emailRules"
              label="E-mail"
              required
          ></v-text-field>

          <v-select
              v-model="select"
              :items="items"
              :rules="[v => !!v || 'Item is required']"
              label="Item"
              required
          ></v-select>

          <v-checkbox
              v-model="checkbox"
              :rules="[v => !!v || 'You must agree to continue!']"
              label="Do you agree?"
              required
          ></v-checkbox>

          <v-btn
              :disabled="!valid"
              color="success"
              class="mr-4"
              @click="validate"

          >
            Send
          </v-btn>

        </v-form>
      </v-sheet>
      <v-flex xl7 lg7 md7 xs12>
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdHTk-sfNEKvw1Ydk8fxzWCTAglit1CYrqmDeol3IAIJCIZGA/viewform?embedded=true"
                width="100%" height="1000" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
      </v-flex>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Contact",
  data: () => ({
    valid: true,
    name: '',
    nameRules: [
      v => !!v || 'Name is required',
      v => (v && v.length <= 10) || 'Name must be less than 10 characters',
    ],
    email: '',
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    select: null,
    items: [
      'Item 1',
      'Item 2',
      'Item 3',
      'Item 4',
    ],
    checkbox: false,
  }),


  methods: {
    validate() {
      this.$refs.form.validate()
    },
    reset() {
      this.$refs.form.reset()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
  },

}
</script>

<style scoped>

</style>