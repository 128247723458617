<template>
  <v-carousel
      cycle
      height="400px"
      hide-delimiter-background
  >
    <v-carousel-item
        v-for="(product, i) in flow"
        :key="product.path"
    >

      <v-layout style="height: 100%;width: 100%">
        <v-img class="pa-0 ma-0"

               :src="require('../assets/'+product.img)"
               height="100%"
        />

        <v-layout style="position: absolute;width: 100%;height: 100%;background: #00000080" align-center>
          <v-flex lg3 xl3 style="margin: 55px"
          >
            <v-sheet color="#22546BB3">
              <v-col style="color: white">
                <h2>{{ product.title}}</h2>

                <v-btn style="margin: 10px" :href="product.path">
                  Details
                </v-btn>

              </v-col>
            </v-sheet>
          </v-flex>

        </v-layout>

      </v-layout>

    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  name: "Carousel",
  data: () => ({
    colors: [
      'primary',
      'secondary',
      'yellow darken-2',
      'red',
      'orange',
    ],
    flow: [
      {title: 'Butterfly Valves', path: '/butterfly-valves', img: 'butterfly_valve.jpg'},
      {title: 'Ball Valves', path: '/ball-valves', img: 'steel-ball-valve.jpg'},
      {title: 'Check Valves', path: '/check-valves', img: 'check_valves.jpg'},
      {title: 'Hemisphere Valves', path: '/hemisphere-valves', img: 'hemisphere_valves.jpg'},
      {title: 'Gas Filtration Modular System', path: '/gas-filtration-modular-system', img: 'gas_filtration.jpg'},
      {title: 'Seal Gas Booster', path: '/seal-gas-booster', img: 'seal_gas_booster.jpg'},
      {title: 'Actuators', path: '/actuators', img: 'actuator_valve.jpg'},
    ]
  })
}
</script>

<style scoped>

</style>