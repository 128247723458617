<template>
  <v-app>

    <v-navigation-drawer
        v-model="toggle"
        fixed app
        v-if="isMobile"
    >
      <img
          :src="require('./assets/aday_muh_main_2.png')"
          height="56px"
      />
      <v-list
          nav
          dense
      >
        <v-list-item-group
            v-model="group"
            active-class="primary--text text--accent-4"
        >
          <div v-for="(item, index) in menus" :key="index">

            <v-list-item v-if="item.subMenu ===undefined" :href="item.path" class="mb-0">
              <v-list-item-title>{{ item.title }}</v-list-item-title>

            </v-list-item>
            <v-divider></v-divider>


            <v-list-group
                v-if="item.subMenu !==undefined"
                :value="false"
            >
              <template v-slot:activator>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </template>

              <div v-for="(header,index) in item.subMenu" :key="index">

                <v-list-item v-if="header.subMenu === undefined" :href="item.path" class="pl-4">
                  <v-list-item-title>{{ header.title }}</v-list-item-title>
                </v-list-item>
                <v-list-group
                    v-else

                    :value="false"
                    no-action
                    sub-group
                >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>{{ header.title }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-group
                      class="pl-2"
                      v-for="(subHeader,index) in header.subMenu"
                      :key="index"
                      :value="false"
                      no-action
                      sub-group
                  >
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title>{{ subHeader.title }}</v-list-item-title>
                      </v-list-item-content>
                    </template>

                    <v-list-item

                        v-for="(product, i) in subHeader.subMenu"
                        :key="i"
                        link
                        :href="product.path"
                    >
                      <v-list-item-title>{{ product.title }}</v-list-item-title>


                    </v-list-item>
                  </v-list-group>
                </v-list-group>

              </div>
            </v-list-group>

          </div>
        </v-list-item-group>


      </v-list>
    </v-navigation-drawer>


    <v-app-bar
        app
        color="white"

        dark
        hide-on-scroll
    >
      <div class="d-flex align-center">
        <v-app-bar-nav-icon transition="scale-transition" v-if="isMobile"
                            style="color:#4398ae;"
                            @click="openNav()"></v-app-bar-nav-icon>


        <router-link to="/" style="z-index: 1">
          <img
              :src="require('./assets/aday_muh_main_2.png')"
              height="56px"
          />

        </router-link>


      </div>


      <Toolbar v-if="!isMobile" style=" width:100%;position: absolute;"/>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
    <Footer/>
  </v-app>
</template>

<script>

import Toolbar from "@/components/Toolbar";
import Footer from "@/components/Footer";

export default {
  name: 'App',
  components: {Footer, Toolbar},
  data: () => ({
    toggle: false,
    group: null,
    items: [
      {title: 'Click Me'},
      {title: 'Click Me'},
      {title: 'Click Me'},
      {title: 'Click Me 2'},
    ],
  }),
  computed: {
    isMobile() {

      let display = this.$vuetify.breakpoint.name;
      return display === 'xs' || display === 'md' || display === 'sm';
    }
  },
  mounted() {
    if (this.$route.meta.title !== 'Aday Mühendislik')
      document.title = this.$route.meta.title + ' | Aday Mühendislik'
    else
      document.title = 'Aday Mühendislik'
  },


  methods: {
    openNav() {
      console.log("clicked")
      this.toggle = !this.toggle

    }
  }
};
</script>
