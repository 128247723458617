<template>
  <v-col class="pa-0 text-center">
    <v-layout align-center style="text-align: initial">

      <v-img
          :src="require('../assets/valve1.jpg')"
          height="400"
          width="100%">

      </v-img>
      <v-sheet
          color="#00000070"
          style="position: absolute;left: 0; margin-left: 10%; margin-right: 10%"
          :width="!isMobile ? '40%'  :'80%'">

        <v-col style="color: white">
          <h2> About Us</h2>
          <v-card-text class="pa-0">
            ADAY Engineering founded in 2020 by Cengiz Aydın & Özkan Adalı to meet each client’s schedule, budget,
            performance & quality goals by applying our wealth of engineering expertise, practical experience & know-how
            and our management skills coming from various industries more than 20 years.
          </v-card-text>

          <v-card-text class="pa-0 mt-3">
            ADAY Engineering continues to grow everyday thanks to the confidence our clients have in us. We cover many
            industries such as Oil&Gas, Petrochemical and Chemical, Power, Water, Mining, Iron & Steel, and Marine
          </v-card-text>
        </v-col>
      </v-sheet>
    </v-layout>
    <v-col style="color: midnightblue">


      <v-flex class="md6 ml-lg-12 mt-lg-12 mb-lg-12">
        <v-sheet
            class="pa-3"
            color="primary"
            width="100%"
            rounded
            style="color:#ffffff; text-align: justify; ">
          <v-col>
            <h1>Mission</h1>
            <v-divider dark class="mb-4"></v-divider>
            <v-card-text
                class="pa-0"
            >Project managing, represent and sell high quality and economized systems and equipment in our sectors to maximize
              satisfaction for our clients, employees and partners.
            </v-card-text>

          </v-col>
        </v-sheet>
      </v-flex>


      <v-flex class="md6 mr-lg-12 mt-lg-12 mb-lg-12" style="margin-top: 20px; margin-left: auto; align-self: end">
        <v-sheet
            class="pa-3"
            color="primary lighten-2"
            width="100%"
            rounded
            style="color:#ffffff; text-align: initial;">
          <v-col>
            <h1>Vision</h1>
            <v-divider light class="mb-4"></v-divider>
            <v-card-text
                class="pa-0"
            >Being a leading company in our sector and create value for our stakeholder and community.
            </v-card-text>

          </v-col>
        </v-sheet>
      </v-flex>

      <v-flex class="md6 ml-lg-12 mt-lg-12 mb-lg-12 " style="margin-top: 20px">
        <v-sheet
            class="pa-3"
            color="primary"
            width="100%"
            rounded
            style="color:#ffffff; text-align: justify; ">
          <v-col>
            <h1>Corporate Values</h1>
            <v-divider dark class="mb-4"></v-divider>

            <ul>
              <li>Reliable: We deliver on our promises everyday.</li>
              <li>Customer Oriented: We aim to better understand each client’s needs and expectations, and provide them
                with the highest quality of service.
              </li>
              <li>Responsive: We are willing to listen and respond quickly, based on a desire to support our clients in
                the best possible way.
              </li>
              <li>Innovative: We embrace the idea of innovation culture and continuously aspire for the best possible
                outcome.
              </li>
            </ul>

          </v-col>
        </v-sheet>
      </v-flex>


    </v-col>

  </v-col>
</template>

<script>
export default {
  name: "AboutUs",
  data: () => ({
    toggle: true
  }),
  computed: {
    isMobile() {
      let display = this.$vuetify.breakpoint.name;
      return display === 'xs' || display === 'md' || display === 'sm';
    }
  }
}
</script>

<style scoped>

</style>