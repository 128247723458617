<template>
  <v-col class="text-center ma-0 pa-0">
    <Carousel></Carousel>
    <v-container style="color: #22546b;">
      <h2>Products</h2>
      <v-divider></v-divider>
      <v-col style="text-align: initial">

        <v-flex class="pa-lg-10 pa-md-0 pa-xl-10 mt-6" v-for="(item,index) in menus[1].subMenu[0].subMenu" :key="index">
          <h3>{{ item.title }}</h3>
          <v-divider></v-divider>
          <v-row align-center class="ma-0 pa-0 mt-6">

            <v-flex xs12 sm12 lg6 md6 xl6>

              <v-card width="100%" height="100%">
                <v-img

                    height="350px"
                    :src="require('../assets/main'+index+'.jpg')">

                </v-img>
              </v-card>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex xs12 sm12 lg4 md4 xl4>

              <v-card width="100%" height="100%">
                <v-sheet height="350px" class="pa-7" style="color: white;align-items: center" color="primary">


                  <a v-for="(product,index) in item.subMenu" :href="product.path">
                    <li style="color: white">{{ product.title }}</li>
                  </a>
                </v-sheet>
              </v-card>
            </v-flex>
          </v-row>

        </v-flex>

      </v-col>

    </v-container>

    <v-layout :style="height">
      <v-img
          :src="require('../assets/valve1.jpg')"
      >

      </v-img>
      <v-sheet
          color="#00000090" width="100%" :height="sheetHeight" class="ma-0 pa-4"
          style="color: white;position: absolute;">

        <v-layout align-center>


          <v-flex lg8 xl8 class="d-flex align-center text-center ml-auto mr-auto">

            <v-row>


              <v-flex xs12 lg6 xl6 md6 style="text-align: initial">
                <v-col class="mt-6">

                  <h1>Why Aday Engineering ? </h1>
                  <v-divider dark></v-divider>
                  <v-card-text class="pa-0 mt-2">
                    As Aday Engineering, our aim is to provide the optimum solution for the demands of our customers by
                    sharing our experience, which we have accumulated over 20 years, both in field application and
                    engineering and project management.
                  </v-card-text>
                </v-col>

              </v-flex>


              <v-flex lg6 xl6 md6 style="text-align: initial;">
                <v-col class="mt-6">

                  <h2>Systems Conforming to International Standards</h2>
                  <v-divider dark></v-divider>
                  <li class="pa-0 mt-2">
                    We serve systems complies to international standards and certified by acredited institutions
                  </li>

                  <h2>Experienced and specialized team</h2>
                  <v-divider dark></v-divider>
                  <li class="pa-0 mt-2">
                    We service you with +20 years experienced engineers
                  </li>

                  <h2>Tailor made solutions according to your needs</h2>
                  <v-divider dark></v-divider>
                  <li class=" pa-0 mt-2">
                    We serve tailor made solutions determined according to your needs
                  </li>
                </v-col>
              </v-flex>


            </v-row>
          </v-flex>
        </v-layout>
      </v-sheet>

    </v-layout>
  </v-col>

</template>

<script>
import Carousel from "@/components/Carousel";
import Vue from "vue";


export default {
  name: 'MainPage',
  components: {Carousel},
  data: () => ({
    height: 'height: 700px',
    sheetHeight: '400px',
    colors: [
      'primary',
      'secondary',
      'yellow darken-2',
      'red',
      'orange',
    ],
    menus: Vue.prototype.menus

  }),
  methods: {
    isMobile() {
      let display = this.$vuetify.breakpoint.name;
      if (display === 'xs' || display === 'md' || display === 'sm') {
        this.height = 'height: 800px'
        this.sheetHeight = '800px'

        return true
      } else {
        this.height = 'height: 400px'
        this.sheetHeight = '400px'

        return false
      }
    }
  },
  mounted() {
    this.isMobile()
  }
}
</script>
