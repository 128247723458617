<template>
  <v-container class="pt-12">

    <v-layout row wrap>
      <v-flex class="lg3 xl3 mr-4">
        <FlowMenu :title="title"></FlowMenu>

      </v-flex>
      <v-flex class="lg8 xl8">
        <v-col class="ma-0 pa-0">

          <v-img class="mr-auto"
                 height="400"
                 :src="require('../../assets/'+img)"
          ></v-img>
          <h2 class="mt-12 mr-3 ml-3" style="color: #22546b">{{ this.$data.title }}</h2>
          <v-divider></v-divider>


            <CardTable :card-info="card_infos"></CardTable>

         <Documents :documents="this.documents"></Documents>
        </v-col>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import FlowMenu from "@/components/FlowMenu";
import CardTable from "@/components/CardTable";
import Documents from "@/components/Documents";


export default {
  name: "ButterflyValve",
  components: {Documents, CardTable, FlowMenu},
  data: () => ({
    img:'butterfly_valve.jpg',
    title: 'Butterfly Valves',
    card_infos: [
      {
        name: 'DESIGN',
        infos: ['ANSI B16.34',
          'ASME VIII',
          '2014/68/UE P.E.D.',
          'FIRE SAFE API 6FA-API 607 / ISO 10497',
          'SIL IEC 61508',
          'ISO 15848 FUGITIVE EMISSIONS'],
      },
      {
        name: 'FACE TO FACE',
        infos: ['ISO 5752 – API 609 – ASME B16.10 – AWWA C504 – EN 55'],
      },
      {
        name: 'SERIES & CLASS',
        infos: ['TE Y 3 ECCENTRIC 150 – 300 – 600 CLASS',
          'TE Q 4 ECCENTRIC 150 – 300 – 600 CLASS'],
      },
      {
        name: 'SERVICE',
        infos: ['ON/OFF',
          'CONTROL'],
      },
      {
        name: 'ENDS',
        infos: ['FLANGED',
          'BUTTWELDING'],
      },
      {
        name: 'SEAL SEATS TYPE',
        infos: ['SOFT',
          'FLEXIBLE METAL',
          'METAL',
          'SOLID METAL RING',
          'LAMELLAR'],
      },
      {
        name: 'COUPLING',
        infos: ['ASME B16.5/ASME B16.47/UNI EN1092-1/ASME B16.25'],
      },
      {
        name: 'TESTING',
        infos: ['FCI 70.2 – API 6D – API 598 – UNI EN 12266'],
      },
      {
        name: 'COUPLING TO ACTUATORS',
        infos: ['ISO 5211 – other on request'],
      },
      {
        name: 'MAIN MATERIALS',
        infos: ['STANILESS STEEL SPECIAL ALLOY'],
      },
      {
        name: 'SEATS OPTIONS',
        infos: ['•\t Multy layered solution made by SS + Graphite',
        '•\t SS. Solid Ring',
        '•\t First one very good tightness also in dirty fluid',
        '•\t Second one very high lifecycle, suggested in clean fluid to obtain zero leakage class.',
        '•\t The fully metal seated valves allow also to respect the main requirements of Fire Safe API 6FA- API 607 , Fugitive Emission ISO 15848 and SIL 61508.'],
      },
    ],
    documents: [
      {name: 'Butterfly Top Entries', pathname: '/butterfly_top_entries.pdf'},
      {name: 'ITV Butterfly Valve', pathname: '/itv_butterfly_valve.pdf'},
      {name: 'ITV Q4 Butterfly Valves', pathname: '/itv_q4_butterfly_valves.pdf'}

    ]
  }),

}
</script>

<style scoped>

</style>