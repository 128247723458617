import Vue from 'vue';
import App from './App';
import router from './router';
import vuetify from './plugins/vuetify'

Vue.prototype.toolbarMenus = [
    {title: 'Home', path: '/'},
    {
        title: 'Products',
        subMenu: [
            {
                title: 'Valves',
                subMenu: [
                    {title: 'Butterfly Valves', path: '/butterfly-valves'},
                    {title: 'Ball Valves', path: '/ball-valves'},
                    {title: 'Check Valves', path: '/check-valves'},
                    {title: 'Hemisphere Valves', path: '/hemisphere-valves'},
                    {title: 'Gas Filtration Modular System', path: '/gas-filtration-modular-system'},
                    {title: 'Seal Gas Booster', path: '/seal-gas-booster'},
                    {title: 'Actuators', path: '/actuators'}
                ]
            },
            {
                title: 'Vacuum Pump & Skid System Solutions',
                path: 'http://www.ppipumps.com/types_of_vacuum_pumps.html',
                subMenu: [
                    {title: 'Liquid Ring Vacuum Pumps', path: 'http://www.ppipumps.com/liquid-ring-vacuum-pump-pl-series.htm'},
                    {title: 'Single Cone Vacuum Pumps', path: 'http://www.ppipumps.com/products_scvp_psc_series.htm'},
                    {title: 'Close Couple Vacuum Pumps', path: 'http://www.ppipumps.com/products_ccvp_cc_series.htm'},
                    {title: 'Two Stage Vacuum Pumps', path: 'http://www.ppipumps.com/products_tsvp_vws_series.htm'},
                    {title: 'Twin Lobe Roots Blower', path: 'http://www.ppipumps.com/products_tlrb_pr_series.htm'},
                    {title: 'Chemical Process Pumps', path: 'http://www.ppipumps.com/products_cpp_pcps_series.htm'},
                    {title: 'Pump Spares Pumps', path: 'http://www.ppipumps.com/products_pump_spares_parts.htm'},
                ]
            },
            {
                title: 'Pressure Vessels',
                path: 'http://www.ppipumps.com/types_of_vacuum_pumps.html',
                subMenu: [
                    {title: 'Heat Exchangers', path: 'https://www.angiolettivessel.it/project/heat-exchangers/'},
                    {title: 'Water Bath Gas Heater', path: 'https://www.angiolettivessel.it/project/water-bath-gas-heater/'},
                    {title: 'Pig Launcher & Receiver Traps', path: 'https://www.angiolettivessel.it/project/launcher-receivers/'},
                    {title: 'Fractionating Columns', path: 'https://www.angiolettivessel.it/project/columns/'},
                    {title: 'Scrubbers', path: 'https://www.angiolettivessel.it/project/scrubber/'},
                    {title: 'Cartridge Filters', path: 'https://www.angiolettivessel.it/project/cartridge-filters/'},
                    {title: 'Vane & Cyclon Filters', path: 'https://www.angiolettivessel.it/project/vane-cyclon-filters/'},
                    {title: 'Two/Three Phase Separators', path: 'https://www.angiolettivessel.it/project/three-phase-separators/'},
                    {title: 'Quick Open Closure', path: 'https://v-lockitalia.com/en/'},
                    {title: 'Hyperbaric Chamber', path: 'https://www.angiolettivessel.it/project/hyperbaric-chamber/'},
                    {title: 'Skid Mounted Solutions', path: 'https://www.angiolettivessel.it/project/skid-mounted-plants/'},
                ]
            }

        ]
    },
    {title: 'About Us', path: '/about'},
    {title: 'Contact', path: '/contact'},
]

Vue.prototype.menus = [
    {title: 'Home', path: '/'},
    {
        title: 'Products',
        subMenu: [

            {
                title: 'Flow Control Solutions',
                subMenu: [
                    {
                        title: 'Valves',
                        subMenu: [
                            {title: 'Butterfly Valves', path: '/butterfly-valves'},
                            {title: 'Ball Valves', path: '/ball-valves'},
                            {title: 'Check Valves', path: '/check-valves'},
                            {title: 'Hemisphere Valves', path: '/hemisphere-valves'},
                            {title: 'Gas Filtration Modular System', path: '/gas-filtration-modular-system'},
                            {title: 'Seal Gas Booster', path: '/seal-gas-booster'},
                            {title: 'Actuators', path: '/actuators'}
                        ]
                    },
                    {
                        title: 'Vacuum Pump & Skid System Solutions',
                        path: 'http://www.ppipumps.com/types_of_vacuum_pumps.html',
                        subMenu: [
                            {title: 'Liquid Ring Vacuum Pumps', path: 'http://www.ppipumps.com/liquid-ring-vacuum-pump-pl-series.htm'},
                            {title: 'Single Cone Vacuum Pumps', path: 'http://www.ppipumps.com/products_scvp_psc_series.htm'},
                            {title: 'Close Couple Vacuum Pumps', path: 'http://www.ppipumps.com/products_ccvp_cc_series.htm'},
                            {title: 'Two Stage Vacuum Pumps', path: 'http://www.ppipumps.com/products_tsvp_vws_series.htm'},
                            {title: 'Twin Lobe Roots Blower', path: 'http://www.ppipumps.com/products_tlrb_pr_series.htm'},
                            {title: 'Chemical Process Pumps', path: 'http://www.ppipumps.com/products_cpp_pcps_series.htm'},
                            {title: 'Pump Spares Pumps', path: 'http://www.ppipumps.com/products_pump_spares_parts.htm'},
                        ]
                    },
                    {
                        title: 'Pressure Vessels',
                        path: 'http://www.ppipumps.com/types_of_vacuum_pumps.html',
                        subMenu: [
                            {title: 'Heat Exchangers', path: 'https://www.angiolettivessel.it/project/heat-exchangers/'},
                            {title: 'Water Bath Gas Heater', path: 'https://www.angiolettivessel.it/project/water-bath-gas-heater/'},
                            {title: 'Pig Launcher & Receiver Traps', path: 'https://www.angiolettivessel.it/project/launcher-receivers/'},
                            {title: 'Fractionating Columns', path: 'https://www.angiolettivessel.it/project/columns/'},
                            {title: 'Scrubbers', path: 'https://www.angiolettivessel.it/project/scrubber/'},
                            {title: 'Cartridge Filters', path: 'https://www.angiolettivessel.it/project/cartridge-filters/'},
                            {title: 'Vane & Cyclon Filters', path: 'https://www.angiolettivessel.it/project/vane-cyclon-filters/'},
                            {title: 'Two/Three Phase Separators', path: 'https://www.angiolettivessel.it/project/three-phase-separators/'},
                            {title: 'Quick Open Closure', path: 'https://v-lockitalia.com/en/'},
                            {title: 'Hyperbaric Chamber', path: 'https://www.angiolettivessel.it/project/hyperbaric-chamber/'},
                            {title: 'Skid Mounted Solutions', path: 'https://www.angiolettivessel.it/project/skid-mounted-plants/'},
                        ]
                    }

                ]
            },

        ]
    },
    {title: 'About Us', path: '/about'},
    {title: 'Contact', path: '/contact'},
]

Vue.prototype.flowMenu = {
    title: 'Flow Control Solutions',
    subMenu: [
        {
            title: 'Valves',
            subMenu: [
                {title: 'Butterfly Valves', path: '/butterfly-valves'},
                {title: 'Ball Valves', path: '/ball-valves'},
                {title: 'Check Valves', path: '/check-valves'},
                {title: 'Hemisphere Valves', path: '/hemisphere-valves'},
                {title: 'Gas Filtration Modular System', path: '/gas-filtration-modular-system'},
                {title: 'Seal Gas Booster', path: '/seal-gas-booster'},
                {title: 'Actuators', path: '/actuators'},
            ]
        },
        {
            title: 'Vacuum Pump & Skid System Solutions',
            path: 'http://www.ppipumps.com/types_of_vacuum_pumps.html',
            subMenu: [
                {title: 'Liquid Ring Vacuum Pumps', path: 'http://www.ppipumps.com/liquid-ring-vacuum-pump-pl-series.htm'},
                {title: 'Single Cone Vacuum Pumps', path: 'http://www.ppipumps.com/products_scvp_psc_series.htm'},
                {title: 'Close Couple Vacuum Pumps', path: 'http://www.ppipumps.com/products_ccvp_cc_series.htm'},
                {title: 'Two Stage Vacuum Pumps', path: 'http://www.ppipumps.com/products_tsvp_vws_series.htm'},
                {title: 'Twin Lobe Roots Blower', path: 'http://www.ppipumps.com/products_tlrb_pr_series.htm'},
                {title: 'Chemical Process Pumps', path: 'http://www.ppipumps.com/products_cpp_pcps_series.htm'},
                {title: 'Pump Spares Pumps', path: 'http://www.ppipumps.com/products_pump_spares_parts.htm'},
            ]
        },
        {
            title: 'Pressure Vessels',
            path: 'http://www.ppipumps.com/types_of_vacuum_pumps.html',
            subMenu: [
                {title: 'Heat Exchangers', path: 'https://www.angiolettivessel.it/project/heat-exchangers/'},
                {title: 'Water Bath Gas Heater', path: 'https://www.angiolettivessel.it/project/water-bath-gas-heater/'},
                {title: 'Pig Launcher & Receiver Traps', path: 'https://www.angiolettivessel.it/project/launcher-receivers/'},
                {title: 'Fractionating Columns', path: 'https://www.angiolettivessel.it/project/columns/'},
                {title: 'Scrubbers', path: 'https://www.angiolettivessel.it/project/scrubber/'},
                {title: 'Cartridge Filters', path: 'https://www.angiolettivessel.it/project/cartridge-filters/'},
                {title: 'Vane & Cyclon Filters', path: 'https://www.angiolettivessel.it/project/vane-cyclon-filters/'},
                {title: 'Two/Three Phase Separators', path: 'https://www.angiolettivessel.it/project/three-phase-separators/'},
                {title: 'Quick Open Closure', path: 'https://v-lockitalia.com/en/'},
                {title: 'Hyperbaric Chamber', path: 'https://www.angiolettivessel.it/project/hyperbaric-chamber/'},
                {title: 'Skid Mounted Solutions', path: 'https://www.angiolettivessel.it/project/skid-mounted-plants/'},
            ]
        }

    ]
},


    new Vue({
        el: '#app',
        router,
        components: {App},
        vuetify,
        template: '<App/>'
    })