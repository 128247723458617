<template>
  <v-container class="pt-12">

    <v-layout row wrap>
      <v-flex class="lg3 xl3 mr-4">
        <FlowMenu :title="title"></FlowMenu>

      </v-flex>
      <v-flex class="lg8 xl8">
        <v-col class="ma-0 pa-0">

          <v-img class="mr-auto"
                 height="400"
                 :src="require('../../assets/'+img)"
          ></v-img>
          <h2 class="mt-12 mr-3 ml-3" style="color: #22546b">{{ this.$data.title }}</h2>
          <v-divider></v-divider>


          <CardTable :card-info="card_infos"></CardTable>

          <Documents :documents="this.documents"></Documents>
        </v-col>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>

import FlowMenu from "@/components/FlowMenu";
import CardTable from "@/components/CardTable";
import Documents from "@/components/Documents";

export default {
  name: "BallValve",
  components: {Documents, CardTable, FlowMenu},
  data: () => ({
    img: 'steel-ball-valve.jpg',
    title: 'Ball Valves',
    card_infos: [
      {
        name: 'DESIGN',
        infos: ['ASME B16.34 - AWWA C504',
          'ASME III - ASME PV CODE 8',
          '2014/68/UE P.E.D.',
          'FIRE SAFE API 6FA / BS6775',
          'ISO 15848 FUGITIVE EMISSIONS'],
      },
      {
        name: 'FACE TO FACE',
        infos: ['ISO 5752',
          'API 609',
          'ASME B16.10',
          'EN 558'],
      },
      {
        name: 'RATINGS / SERIES & CLASS',
        infos: [
          'S    (Spilt Body) FULL & REDUCER BORE   ANSI 150 300',
          'SW   (Socket Weld) FULL & REDUCER BORE  ANSI 150 300',
          'T    (Trunnion) FULL & REDUCER BORE     ANSI 150 300',
          '                                             600 900',
          '                                           1500 2500',
          'TE   (Top Entry) FULL & REDUCER BORE    ANSI 150 300'
        ],
      },
      {
        name: 'SERVICE',
        infos: ['ON/OFF'],
      },
      {
        name: 'ENDS',
        infos: [
          'FLANGED',
          'BUTT WELDING',
          'RING JOINT'],
      },
      {
        name: 'SEAL SEATS TYPE',
        infos: ['SOFT',
          'FLEXIBLE METAL',
          'METAL',
          'LAMELLAR'],
      },
      {
        name: 'ENDS COUPLING',
        infos: ['UNI EN 1092 – ASME B16.5',
          'ASME B16.47 – AWWA C207',
          'MSS/SP44 – ASME B16.25'],
      },
      {
        name: 'TESTING',
        infos: ['MSS-SP61 – FCI 70.2',
          'API 598 – API 6D',
          'Special testing on request'],
      },
      {
        name: 'COUPLING TO ACTUATORS',
        infos: ['ISO 5211 – other on request'],
      },
      {
        name: 'STANDART MATERIALS',
        infos: [
          'BODY      : WCB - A105 – CF8 - CF8M – DUPLEX\n' +
          'Other on request',
          'BALL      :  WCB + ENP - A105 + ENP - CF8 - CF8M - DULPLEX - CA6NM\n' +
          'Other on request',
          'STEM      : AISI 316 – AISI 420B – 17.4.PH MONEL NITRONIC 50\n' +
          'Other on request',
          'SEAL SEAT :  PTFE / FILLED – PTFE – AISI 316+PTFE\n' +
          'Other on request',
          'STEM SEAL : PTFE+NBR / VITON / SILICONE / KALREZ /\n' +
          'CARBOGRAPHITE NBR / VITON / SILICONE\n' +
          'KALREZ / GRAPHITE'],
      },

    ],
    documents: [
      {name: 'Ball Valves', pathname: '/ball_valves.pdf'},


    ]
  }),
}
</script>

<style scoped>

</style>