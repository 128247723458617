<template>
  <v-container class="pt-12">

    <v-layout row wrap>
      <v-flex class="lg3 xl3 mr-4">
        <FlowMenu :title="title"></FlowMenu>

      </v-flex>
      <v-flex class="lg8 xl8">
        <v-col class="ma-0 pa-0"  >

          <v-img class="mr-auto"
                 height="400"
                 :src="require('../../assets/'+img)">

          </v-img>
          <h2 style="color: #22546b"
              class="mt-12 mr-3 ml-3 ">{{ title }}
          </h2>
          <v-divider class="ml-3 mr-3"></v-divider>

          <ul class="mt-4 mr-3 ml-3" style="color:#4398ae;">
            <li>
              <h3>PNEUMATIC</h3>
            </li>
            <li>
              <h3>FAIL SAFE ELECTRO-MECHANICAL SPRING</h3>
            </li>
            <li>
              <h3>HYDRAULIC & ELECTRO-HYDRAULIC</h3>
            </li>
            <li>
              <h3>HYDRAULIC COUNTER-WEIGHT</h3>
            </li>

          </ul>

          <v-img class="mt-2 mr-auto"

                 :src="require('../../assets/actuators.jpg')">

          </v-img>
          <Documents :documents="documents"></Documents>
        </v-col>
      </v-flex>

    </v-layout>
  </v-container>
</template>

<script>
import Documents from "@/components/Documents";
import FlowMenu from "@/components/FlowMenu";

export default {
  name: "Actuators",
  components: {Documents, FlowMenu},
  data: () => ({
    img:'actuator_valve.jpg',
    title: 'Actuators',
    documents: [
      {name: 'ITV Actuators', pathname: '/itv-actuators.pdf'}
    ]
  })
}
</script>

<style scoped>

</style>