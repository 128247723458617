<template>
  <v-container class="pt-12">

    <v-layout row wrap>
      <v-flex class="lg3 xl3 mr-4">
        <FlowMenu :title="title"></FlowMenu>

      </v-flex>
      <v-flex class="lg8 xl8">
        <v-col class="ma-0 pa-0">

          <v-img class="mr-auto"
                 height="400"
                 :src="require('../../assets/'+img)"
          ></v-img>
          <h2 class="mt-12 mr-3 ml-3" style="color: #22546b">{{ this.$data.title }}</h2>
          <v-divider></v-divider>


          <CardTable :card-info="card_infos"></CardTable>

          <Documents :documents="this.documents"></Documents>
        </v-col>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Documents from "@/components/Documents";
import CardTable from "@/components/CardTable";
import FlowMenu from "@/components/FlowMenu";

export default {
  name: "HemisphereValves",
  components: {Documents, CardTable, FlowMenu},
  data: () => ({
    img:'hemisphere_valves.jpg',
    title: 'Hemisphere Valves',
    card_infos: [
      {
        name: 'DESIGN',
        infos: ['ASME B16.34',
          'ASME III - ASME PV CODE 8',
          '2014/68/UE P.E.D.',
          'ISO 15848 FUGITIVE EMISSIONS'],
      },
      {
        name: 'FACE TO FACE',
        infos: ['ASME B16.10 - EN 558',
          'ANSI/ISA S75.04'],
      },
      {
        name: 'SERIES & CLASS',
        infos: [
          'WKP  ANSI 150-300-600',
          'WKPF ANSI 150-300-600-900',
          'VPS  ANSI 150-300-600-900'],
      },
      {
        name: 'SERVICE',
        infos: ['CONTROL'],
      },
      {
        name: 'ENDS',
        infos: ['WAFER',
          'FLANGED'],
      },
      {
        name: 'SEAL SEATS TYPE',
        infos: [
          'FLEXIBLE METAL',
          'METAL'
        ],
      },
      {
        name: 'COUPLING TO FLANGES',
        infos: ['UNI EN 1092 - ASME B16.5 - ASME B16.47'],
      },
      {
        name: 'SIZE',
        infos: ['from 2’’ inches to 30 inches'],
      },
      {
        name: 'TESTING',
        infos: ['MSS-SP61 – FCI 70.2',
          'Special testing on request'],
      },
      {
        name: 'COUPLING TO ACTUATORS',
        infos: ['ISO 5211 – Other on request'],
      },
      {
        name: 'STANDART MATERIALS',
        infos: ['BODY : WCB – CF8 - CF8M – DUPLEX',
          'Other on request',
          'BUTTERFLY :  CF8M – DUPLEX – CA6NM',
          'Other on request',
          'STEM : AISI 316 – AISI 420B – 17.4.PH MONEL NITRONIC 50',
          'Other on request',
          'SEAL SEAT : ',
          'Flexible Metal Seat: AISI 430 - AISI 316 INCOLOY',
          'Metal Seat: Incoloy- AISI 316 + Stellite AISI 316 Hardened',
          'STEM SEAL :  PTFE+NBR / VITON / SILICONE / KALREZ / CARBOGRAPHITE',
          '+ NBR / VITON / SILICONE / KALREZ / GRAPHITE'],
      },

    ],
    documents: [
      {name: 'Hemisphere Valves Catalog', pathname: '/hemisphere_valves_catalog.pdf'},
    ]
  }),
}
</script>

<style scoped>

</style>