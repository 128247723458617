import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme:{
        themes:{
            light:{
                primary: '#22546b',
                secondary: '#4398ae',
                accent: '#819aa4',
                error: '#b71c1c',
            },
            dark:{
                primary: '#22546b',
                secondary: '#4398ae',
                accent: '#819aa4',
                error: '#b71c1c',
            }
        }
    }
});
